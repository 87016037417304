<div mat-dialog-conten>
    <ng-container *ngTemplateOutlet="template"></ng-container>
</div>
<mat-dialog-actions class="flex justify-end">
    <button mat-flat-button (click)="onCancel()">{{'CANCEL' | translate}}</button>
    <ng-container *ngFor="let action of actions">
        <button mat-flat-button [color]="action.color" (click)="onAction(action)">
            <span class="text-white">{{ action.label | translate }}</span>
        </button>
    </ng-container>
</mat-dialog-actions>