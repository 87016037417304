import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, map } from 'rxjs';
import { ApiHeaders } from './api.headers';
import { ErrorHandler } from '../core/handlers/error.handler';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiBaseService {
  private readonly _httpClient: HttpClient;
  private _moduleEndpoint: string;

  constructor(
    httpClient: HttpClient,
    @Inject('moduleEndpoint') moduleEndpoint: string
  ) {
    this._httpClient = httpClient;
    this._moduleEndpoint = moduleEndpoint;
  }

  public get(
    endpoint: string,
    responseType: string = 'json',
    acceptanceType: string = 'json',
    observeReponse: string = 'body'
  ): Observable<any> | any {
    return this._httpClient
      .get(
        `${environment.apiUrl}/${this._moduleEndpoint}/${endpoint}`,
        ApiHeaders.defaultHeaders(responseType, acceptanceType, observeReponse)
      )
      .pipe(
        map((data: any) => data),
        catchError((error) => ErrorHandler.Catch<any>(error))
      );
  }

  public post(
    endpoint: string,
    data: any,
    responseType: string = 'json',
    acceptanceType: string = 'json'
  ): Observable<any> | any {
    return this._httpClient
      .post(
        `${environment.apiUrl}/${this._moduleEndpoint}/${endpoint}`,
        data,
        ApiHeaders.defaultHeaders(responseType, acceptanceType)
      )
      .pipe(
        map((data: any) => data),
        catchError((error) => ErrorHandler.Catch<any>(error))
      );
  }

  public put(
    endpoint: string,
    data: any | any[],
    responseType: string = 'json',
    acceptanceType: string = 'json'
  ): Observable<any> | any {
    return this._httpClient
      .put(
        `${environment.apiUrl}/${this._moduleEndpoint}/${endpoint}`,
        data,
        ApiHeaders.defaultHeaders(responseType, acceptanceType)
      )
      .pipe(
        map((data: any) => data),
        catchError((error) => ErrorHandler.Catch<any>(error))
      );
  }

  public delete(
    endpoint: string,
    responseType: string = 'json',
    acceptanceType: string = 'json'
  ): Observable<any> | any {
    return this._httpClient
      .delete(
        `${environment.apiUrl}/${this._moduleEndpoint}/${endpoint}`,
        ApiHeaders.defaultHeaders(responseType, acceptanceType)
      )
      .pipe(
        map((data: any) => data),
        catchError((error) => ErrorHandler.Catch<any>(error))
      );
  }
}
