import { User } from "app/core/user/user.types";
import { Permissions } from "app/modules/admin/pages/settings/permission-settings/models/permission";
import { SignInResponse } from "app/modules/auth/sign-in/models/sign-in.response";

export class StorageService {
    static getItem(key: string, storage = localStorage): any {
      return storage.getItem(key) ?? '';
    }
  
    static setItem(key: string, value: any, storage = localStorage): any {
      return storage.setItem(key, value);
    }

    static unsetItem(key: string): any {
      return localStorage.removeItem(key)
    }

    static unsetLocalStorage(storage = localStorage): any {
      return storage.clear()
    }
  
    static getStorageKeys(storage = localStorage) {
      return Object.keys(storage)
    }

    static setSignInState(response: SignInResponse): any {
      StorageService.setItem('accessToken', response.token);
      StorageService.setItem('user', JSON.stringify(response.user));
      StorageService.setItem('company', JSON.stringify(response.company));
      StorageService.setItem('permissions', JSON.stringify(response.permissions));
    }

    static setUserState(user: User) : any{
      StorageService.setItem('user', JSON.stringify(user));
    }

    static setCompanyState(company: any) : any{
      StorageService.setItem('user', JSON.stringify(company));
    }

    static setPermissionsState(permissions: Permissions) : any{
      StorageService.setItem('permissions', JSON.stringify(permissions));
    }
  }
  