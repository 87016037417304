import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'appointments' },

    // Redirect signed-in user to the '/dashboards/project'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'appointments',
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
        ],
    },

    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirm-invitation',
                loadChildren: () =>
                    import(
                        'app/modules/auth/verification/verification.module'
                    ).then((m) => m.VerificationModule),
            },
            {
                path: 'sign-up-for-company',
                loadChildren: () =>
                    import('app/modules/auth/sign-up-for-company/sign-up-for-company.module')
                        .then((m) => m.AuthSignUpForCompanyModule),
            },
            {
                path: 'event-status',
                loadChildren: () =>
                    import(
                        'app/modules/confirmations/event-confirmation/event-confirmation.module'
                    ).then((m) => m.EventConfirmationModule),
            },
        ]
    },

    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirm-invitation',
                loadChildren: () =>
                    import(
                        'app/modules/auth/verification/verification.module'
                    ).then((m) => m.VerificationModule),
            },
            {
                path: 'sign-up-for-company',
                loadChildren: () =>
                    import('app/modules/auth/sign-up-for-company/sign-up-for-company.module')
                        .then((m) => m.AuthSignUpForCompanyModule),
            },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Invitation routes


    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [

            // Dashboard
            {
                path: 'dashboard',
                loadChildren: () =>
                    import(
                        'app/modules/admin/dashboard/dashboard.module'
                    ).then((m) => m.DashboardModule),
            },

            // Timers
            {
                path: 'timers',
                loadChildren: () =>
                    import(
                        'app/modules/admin/timers/timers.module'
                    ).then((m) => m.TimersModule),
            },

            // Articles
            {
                path: 'articles',
                loadChildren: () =>
                    import(
                        'app/modules/admin/articles/articles.module'
                    ).then((m) => m.ArticlesModule),
            },

            // Stock Management
            // In/Out - Booking
            {
                path: 'booking',
                loadChildren: () =>
                    import(
                        'app/modules/admin/stock-management/booking/booking.module').then(
                            (m) => m.BookingModule
                        ),
            },

            // Inventory
            {
                path: 'inventory',
                loadChildren: () =>
                    import(
                        'app/modules/admin/stock-management/inventory/inventory.module').then(
                            (m) => m.InventoryModule
                        ),
            },

            // Documents 
            {
                path: 'documents',
                loadChildren: () =>
                    import(
                        'app/modules/admin/documents/documents.module'
                    ).then((m) => m.DocumentsModule),
            },

            // Clients 
            {
                path: 'clients',
                loadChildren: () =>
                    import(
                        'app/modules/admin/clients/clients.module'
                    ).then((m) => m.ClientsTableModule),
            },

            // Projects 
            {
                path: 'projects',
                loadChildren: () =>
                    import(
                        'app/modules/admin/projects/projects.module'
                    ).then((m) => m.ProjectsModule),
            },

            //  Appointments
            {
                path: 'appointments',
                loadChildren: () =>
                    import(
                        'app/modules/admin/appointments/appointments.module'
                    ).then((m) => m.AppointmentsModule),
            },

            // Apps
            {
                path: 'apps',
                children: [
                    {
                        path: 'file-manager',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/file-manager/file-manager.module'
                            ).then((m) => m.FileManagerModule),
                    },
                ],
            },

            // Pages
            {
                path: 'pages',
                children: [

                    // Error
                    {
                        path: 'error',
                        children: [
                            {
                                path: '404',
                                loadChildren: () =>
                                    import(
                                        'app/modules/admin/pages/error/error-404/error-404.module'
                                    ).then((m) => m.Error404Module),
                            },
                        ],
                    },

                    // Profile
                    {
                        path: 'profile',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/profile/profile.module'
                            ).then((m) => m.ProfileModule),
                    },

                    // Settings
                    {
                        path: 'settings',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/settings/settings.module'
                            ).then((m) => m.SettingsModule),
                    },
                ],
            },

            // 404 & Catch all
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/admin/pages/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module),
            },
            { path: '**', redirectTo: '404-not-found' },

        ],
    },
];
