import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnackbarComponent } from './shared-components/snackbar-component/snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { FormatCurrencyPipe } from './format-currency.pipe';
import { CustomPaginatorIntl } from './custom-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomDialogComponent } from './shared-components/custom-dialog/custom-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [SnackbarComponent, FormatCurrencyPipe, CustomDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatSnackBarModule,
        TranslateModule,
        MatDialogModule,
        MatButtonModule
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SnackbarComponent,
        FormatCurrencyPipe,
        CustomDialogComponent
    ],
})
export class SharedModule { }
