import { HttpHeaders } from '@angular/common/http';
import { StorageKeys } from '../core/services/storage/storage.keys';
import { StorageService } from '../core/services/storage/storage.service';

export class ApiHeaders {
  constructor() {}

  public static defaultHeaders(
    responseTypeParameter: string,
    acceptanceType: string,
    observeResponse = 'body'
  ): object {
    const requestOptions: object = {
      headers: new HttpHeaders()
        .set(
          'Authorization',
          `Bearer ${StorageService.getItem(StorageKeys.ACCESS_TOKEN)}`
        )
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/' + acceptanceType),
      responseType: responseTypeParameter,
      observe: observeResponse,
    };

    return requestOptions;
  }

  public static twoFactorAuthHeader(code: string): HttpHeaders {
    return new HttpHeaders()
      .set('Authorization', `Bearer ${code}`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('x-otp', code);
  }
}
