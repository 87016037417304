/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'Dashboard',
        title: 'DASHBOARD',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
    },
    {
        id: 'Timers',
        title: 'TIMERS',
        type: 'basic',
        icon: 'heroicons_outline:clock',
        link: '/timers'
    },
    {
        id: 'Article',
        title: 'ARTICLES',
        type: 'basic',
        icon: 'heroicons_outline:view-list',
        link: '/articles'
    },
    {
        id: 'InventoryManagement',
        title: 'INVENTORYMANAGEMENT',
        type: 'group',
        icon: 'heroicons_outline:clipboard-list',
        children: [
            {
                id: 'in-out',
                title: 'IN/OUT',
                type: 'basic',
                icon: 'heroicons_outline:arrow-path-rounded',
                link: '/booking'
            },
            {
                id: 'inventory',
                title: 'INVENTORY',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-list',
                link: '/inventory'
            },
        ]
    },
    {
        id: 'Documents',
        title: 'DOCUMENTS',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/documents'
    },
    {
        id: 'Clients',
        title: 'CLIENTS',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/clients'
    },
    // {
    //     id: 'Projects',
    //     title: 'PROJECTS',
    //     type: 'basic',
    //     icon: 'mat_outline:splitscreen',
    //     link: '/projects'
    // },
    {
        id: 'Appointments',
        title: 'APPOINTMENTS',
        type: 'basic',
        icon: 'mat_outline:edit_calendar',
        link: '/appointments'
    },
];


export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'Dashboard',
        title: 'DASHBOARD',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
    },
    {
        id: 'Timers',
        title: 'TIMERS',
        type: 'basic',
        icon: 'heroicons_outline:clock',
        link: '/timers'
    },
    {
        id: 'Article',
        title: 'ARTICLES',
        type: 'basic',
        icon: 'heroicons_outline:view-list',
        link: '/articles'
    },
    {
        id: 'InventoryManagement',
        title: 'INVENTORYMANAGEMENT',
        type: 'group',
        icon: 'heroicons_outline:clipboard-list',
        children: [
            {
                id: 'in-out',
                title: 'IN/OUT',
                type: 'basic',
                icon: 'heroicons_outline:arrow-path-rounded',
                link: '/booking'
            },
            {
                id: 'inventory',
                title: 'INVENTORY',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-list',
                link: '/inventory'
            },
        ]
    },
    {
        id: 'Documents',
        title: 'DOCUMENTS',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/documents'
    },
    {
        id: 'Clients',
        title: 'CLIENTS',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/clients'
    },
    // {
    //     id: 'Projects',
    //     title: 'PROJECTS',
    //     type: 'basic',
    //     icon: 'mat_outline:splitscreen',
    //     link: '/projects'
    // },
    {
        id: 'Appointments',
        title: 'APPOINTMENTS',
        type: 'basic',
        icon: 'mat_outline:edit_calendar',
        link: '/appointments'
    }
];
