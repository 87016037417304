import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _urlParam: any;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
    */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
    */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    public get selectedPanel(): string {
        let currentRoute = this._router.url.split('/')[3];
        return currentRoute === 'permissions' ? 'permission-settings' : currentRoute;
    }

    public get selectedPanelForClientEdit(): string {
        return this._router.url.split('/')[2];
    }

    public get selectedPanelForCompany(): string {
        return this._router.url.split('/')[5];
    }

    public get urlParam() {
        return this._urlParam;
    }

    public set urlParam(value) {
        this._urlParam = value;
    }

    isEdit(): boolean {
        var splitedUrl = this._router.url.split('/');
        // check if last member of the splitedUrl params is new
        if (!splitedUrl[splitedUrl.length - 1].includes('new')) {
            // This is an edit route
            this._urlParam = splitedUrl[splitedUrl.length - 1];
            return true
        }

        return false;
    }

    isEditable(): boolean {
        var splitedUrl = this._router.url.split('/');
        // check if last member of the splitedUrl params is new
        if (splitedUrl[splitedUrl.length - 1] != 'new') {
            // This is an edit route
            this._urlParam = splitedUrl[splitedUrl.length - 2];
            return true
        }
        return false;
    }

    isDuplicate(): boolean {
        const splitedUrl = this._router.url.split('/');
        return splitedUrl.includes('duplicate');
    }

    continueTo(): boolean {
        return this._router.url.includes('documentReference');
    }
}
