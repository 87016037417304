import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'my-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent implements OnInit {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {
    }

    get getIcon(): string {
        switch (this.data.snackType.type) {
            case 'success':
                return 'heroicons_solid:check-circle';
            case 'error':
                return 'heroicons_solid:x-circle';
            case 'warning':
                return 'heroicons_solid:exclamation';
            case 'info':
                return 'heroicons_solid:information-circle';
        }
    }

    get getColor(): string {
        switch (this.data.snackType.type) {
            case 'success':
                return 'success-color';
            case 'error':
                return 'error-color';
            case 'warning':
                return 'warning-color';
            case 'info':
                return 'info-color';
        }
    }

    ngOnInit(): void { }

}
