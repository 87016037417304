import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
    changes = new Subject<void>();

    constructor(private _translateService: TranslateService) {
        super();
        this.translateLabels();

        this._translateService.onLangChange.subscribe(() => {
            this.translateLabels();
            this.changes.next();
        });
    }

    private translateLabels() {
        this.itemsPerPageLabel = this._translateService.instant('ITEMS_FOR_PAGE');
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this._translateService.instant('OF')} ${length}`;
        }
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this._translateService.instant('OF')} ${length}`;
    };
}
