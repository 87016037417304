import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor(
        private _translateService: TranslateService
    ) {
        // // Add languages
        this._translateService.addLangs(['en', 'de']);

        // // Set the default language
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang('de');
    }
}
