import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';
import { TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';


@NgModule({
    imports  : [
        HttpClientModule,
        TranslateModule.forChild(),
    ],
    providers: [
        AuthService,
        TranslateService,
        TranslateStore,
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi   : true
        }
    ]
})
export class AuthModule
{
}
