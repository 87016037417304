<div class="grid grid-rows-1 grid-flow-col gap-2">
    <div class="mt-3 row-span-1">
        <mat-icon [class]="getColor" [svgIcon]="getIcon"></mat-icon>
    </div>
    <div class="mt-3 row-span-2">
        <div>
            <span class="text-base" data-testid="message">{{
                data.message | translate
                }}</span>
        </div>
    </div>
</div>